@media (min-width: 768px) {
.footer {
    background: #FFFEED;
    color: black;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
}

.footer-about {
    display: flex;
    flex-flow: row nowrap;
    padding: 1vmin;
}
}

.active {
    color:black;
}

.footer-about a, img, p{
    color:black;
    font-family: Roboto;
    padding: 5vh;
}

@media screen and (max-width: 768px) {
    .footer{
        background: #FFFEED;
        color: black;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
        padding: calc(2px + 2vmin);
        font-size: 3vmin;
    }


    .footer-about a, img{
        display: flex;
        flex-flow: column nowrap;
        padding: 1vmin;
        text-align: center;
    }
}

img {
    display: flex;
    align-self: center;
    margin: auto auto;
    height: 20vmin;
}

#ig {
    height: 5vmin;
    display: flex;
}

.copyright {
    color: #112886;
}