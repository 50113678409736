.join h2 {
    font-family: Sumana;
}

.join p {
    font-family: Roboto;
    color: white;
}

@media screen and (max-width: 768px) {


.join {
    display: flex;
    background: #FFFEED;
    padding: calc(5px + 2vmin);
    justify-content: center;
}
}

@media (min-width: 768px) {


  .join {
      display: flex;
      background: #FFFEED;
      padding: calc(5px + 2vmin);
      justify-content: center;
  }

  }
  .content {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    bottom: 0;
    background: black;
    color: #8FC4D7;
    width: 90%;
    padding: 20px;
  }

  .join-myBtn {
    width: 95px;
    font-size: 18px;
    padding: 15px;
    border: none;
    background-color: #FF914D;
    color: black;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-align: center;
    display: flex;
    border-radius: 30px;
    font-family: Roboto;
  }
  
  .join-myBtn:hover {
    background: white;
    color:black;
  }