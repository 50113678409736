.quote h2 {
    font-family: Sumana;
    line-height: 30px;
}

.quote p {
    font-family: Roboto;
}



.quote a{
  color: white;
}


.quote {
    display: flex;
    padding: calc(5px + 2vmin);
    justify-content: center;
    background: #8FC4D7;
}

  .quote-content {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    width: 50%;
    padding: 20px;
  }

  .quote-myBtn {
    width: 160px;
    padding: 15px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    display: flex;
    align-self: center;
    text-align: center;
    display: flex;
  }
  