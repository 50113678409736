.portfolio {
    text-align: center;
    padding: 5vmin;
    color: white;
    text-align: center;

  }



.portfolio h2 {
    font-family: Sumana;
    color:white;
    font-size: 30px;
    line-height: 30px;

}

.portfolio h3 {
    font-family: Roboto;;
    line-height: 30px;

}

/* ============================= */

@media screen and (max-width: 768px) {
    .makegood, .ppw {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background: #112886;
        font-weight: 500;
        display: flex;
        line-height: 30px;
        font-family: Roboto;
        margin: auto auto;
        text-align: center;

    }


}

@media (min-width: 768px) {
.makegood, .ppw {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    background: #112886;
    font-weight: 500;
    line-height: 30px;
    font-family: Roboto;
    text-align: center;


}


}

.makegood a {
    color: #8FC4D7;
    text-align: center;
}

.makegood-text {
    display: flex;
    flex-direction: column;
    padding: 5vmin;
    color:white;
    line-height: 30px;
    font-family: Roboto;
    text-align: center;
}

.makegood-pic img {
    min-height: 30vmin;
}

.makegood-pic {
    display: flex;
    padding: 5vmin;
}


/* ============================= */


