.header {
  text-align: center;
  font-family: Roboto;
  padding: 1px;
}

@media (min-width: 768px) {
  .logo {
    height: 30vmin;
    pointer-events: none;
    display: flex;
    padding: 1px;
  }
  }

@media screen and (max-width: 768px) {
.logo {
  height: 20vmin;
  pointer-events: none;
  display: flex;
}
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */




  .top {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: calc(1px + 2vmin);
    background: rgb(255,254,237);
    padding: 2vmin;
  }

  .top a {
    text-decoration: none;
    padding: 2vmin;
    font-family: Roboto;
    color: black;
    flex-flow: column nowrap;
    display: flex;
  }

  #nav-icon {
    display: flex;
    height: 5vmin;
  }

  .mobile {
    display: block;

  }





/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
