.active {
    color:#CDD5D1;
}

a {
    text-decoration: none;
    align-self: center;
}

.usps-heading h2 {
    font-family: Sumana;
    text-align: center;
    font-size: 30px;
    padding: 20px;
    color: #FF914D;
    line-height: 40px;
}

.usps h3 {
    font-family: Roboto;
    line-height: 30px;
    text-align: center;
}
@media screen and (max-width: 768px) {
.usps {
    color: white;
    display: flex;
    flex-flow: column nowrap;
    background: #112886;
    padding: 20px;
    font-weight: 500;
    justify-content: center;

  }
}
@media (min-width: 768px) {
    .usps {
        color: white;
        display: flex;
        flex-flow: column nowrap;
        padding: 20px;
        font-weight: 500;
        background: #112886;
        justify-content: center;
      }
    }

.big-group {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
.small-group-1, .small-group-2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
}
}

@media (min-width: 768px) {
    .small-group-1, .small-group-2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
    }
    }

.usp1, .usp2, .usp3, .usp4, .usp5, .usp6 {
    background-color: rgb(0,0,0, .5);
    padding: 30px;
    margin: 10px;
}