.hero h1, h2, h3 {
    font-family: Sumana;
    line-height: 30px;
}

.hero button:hover {

    background: #8FC4D7;
    color:black;
  
}

.hero-content p{
  color: black;
  font-family: Roboto;
  line-height: 30px;
}

.hero-content2 p {
    font-family: Roboto;
    line-height: 30px;
    color: #8FC4D7;
}

#metanin{
  height: 30vmin;
  margin: auto auto;
}

.one {
  display: flex;
}


.two {
  display: flex;
}

.section1 {
  display: flex;
  margin: auto auto;
  justify-content: center;
  padding: 10px;
}

.section2 {
  display: flex;
  margin: auto auto;
  justify-content: center;
  padding: 10px;
}

.hero {
  display: flex;
  justify-content: space-evenly;
  flex-flow: column nowrap;
}

@media (min-width: 768px) {

.hero {
    padding: calc(5px + 2vmin);
    margin: auto auto;
}
.two {
  flex-flow: row nowrap;
}



}

@media screen and (max-width: 768px) {

  .hero {
      margin: auto auto;
  }

  .two {
    flex-flow: column nowrap;
  }
  
  }

  .hero-content {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    color: black;
    width: 100%;
    padding: 10px;
    font-weight: 500;
    background: rgb(255,254,237);
    background: linear-gradient(180deg, rgba(255,254,237,1) 22%, rgba(143,196,215,1) 93%);
    justify-content: center;
  }

  .hero-content2 {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    background: url('./blackbg.png');
    color: white;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    width: 80%;
    padding: 10px;
    font-weight: 500;
  }

  #myBtn {
    width: 125px;
    font-family: Roboto;
    font-size: 18px;
    padding: 15px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    display: flex;
    margin: auto auto;
    display: flex;
    border-radius: 30px;
  }
  
  #myBtn:hover {
    background: #8FC4D7;
    color:black;
  }

  #myBtn2 {
    width: 135px;
    font-family: Roboto;
    font-size: 18px;
    padding: 15px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    display: flex;
    margin: auto auto;
    display: flex;
    border-radius: 30px;
  }

  #myBtn2:hover,   #myBtn:hover {
    background: #8FC4D7;
    color:black;
  }