.about h2 {
  font-family: Sumana;
  color:#8FC4D7;
  font-size: 30px;
}

.about p {
    font-family: Roboto;
    font-weight: 500;
    line-height: 30px;
}


.about {
    display: flex;
    padding: calc(5px + 2vmin);
    background: url('./artgallery.png');
    background-size: cover;
    background-attachment: fixed;
    justify-content: center;
    line-height: 50px;

}

  .about-content {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    bottom: 0;
    color: #ffffff;
    width: 50%;
    padding: 20px;
    background: rgba(0,0,0,0.7);
    line-height: 50px;

  }

 .about-content h3 {
  line-height: 30px;
  font-family: Roboto;
 }

 @media screen and (max-width: 768px) {
  .about {
  background-size: auto;
  }
 }